<template>
  <div class="spareParts-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="content" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 表单 -->
        <ml-form
          ref="sparePartsFormRef"
          labelWidth="200px"
          style="width: 1200px; margin: 20px auto 40px"
          :model="sparePartsForm"
          :disabled="true"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">备品备件信息</el-divider>
          <div class="globalTitle">备品备件详情</div>
          <el-row>
            <el-col :span="12">
              <ml-select
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                v-model="sparePartsForm.companyId"
                @change="changeCompany"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="partName"
                label="备件名称:"
                placeholder="请输入备件名称"
                v-model.trim="sparePartsForm.partName"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input
                prop="partCode"
                label="备件编号:"
                placeholder="请输入备件编号"
                v-model.trim="sparePartsForm.partCode"
              />
            </el-col>
            <el-col :span="12">
              <!-- 所属项目 -->
              <ml-select
                prop="projectId"
                placeholder="请选择所属项目"
                :options="projectData"
                label="所属项目:"
                keyName="projectId"
                labelName="projectName"
                valueName="projectId"
                v-model="sparePartsForm.projectId"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-date-picker
                prop="productDate"
                placeholder="请选择生产日期"
                label="生产日期:"
                type="date"
                v-model="sparePartsForm.productDate"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="model"
                label="规格/型号:"
                placeholder="请输入规格/型号"
                v-model.trim="sparePartsForm.model"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input
                prop="unit"
                label="单位:"
                placeholder="请输入单位"
                v-model.trim="sparePartsForm.unit"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="deadline"
                label="使用期限(月):"
                placeholder="请输入使用期限"
                v-model.trim="sparePartsForm.deadline"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-input-number
                prop="planStock"
                label="计划库存:"
                :min="0"
                placeholder="请输入计划库存"
                v-model="sparePartsForm.planStock"
              />
            </el-col>
            <el-col :span="12">
              <ml-input-number
                prop="realStock"
                label="实际库存:"
                :min="0"
                placeholder="请输入实际库存"
                :disabled="true"
                v-model="sparePartsForm.realStock"
              />
            </el-col>
          </el-row>

          <ml-input
            prop="storageAddress"
            label="存放地址:"
            placeholder="请输入存放地址"
            v-model.trim="sparePartsForm.storageAddress"
          />
          <!-- <el-row>
            <el-col :span="24">
              <ml-select
                prop="orderProcess.processId"
                placeholder="请选择审批流程"
                :options="processData"
                label="审批流程:"
                keyName="processId"
                labelName="processName"
                valueName="processId"
                v-model="sparePartsForm.orderProcess.processId"
              />
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <ml-input
                prop="orderProcess.content"
                label="入库原因:"
                placeholder="请输入入库原因"
                v-model.trim="sparePartsForm.orderProcess.content"
                type="textarea"
                row="3"
              />
            </el-col>
          </el-row> -->
          <div class="globalTitle" v-if="processList.length > 0">流程进度</div>
          <el-form-item v-if="processList.length > 0" style="margin-bottom: 60px">
            <process-schedule
              disabled
              v-for="(item, index) in processList"
              :key="item"
              :item="item"
              :state="index === processList.length - 1"
            />
          </el-form-item>
        </ml-form>
        <div class="goBack-box" style="margin-top: 0">
          <el-button size="medium" icon="el-icon-circle-close" @click="clickCancelSparePartsr">
            返回上一页
          </el-button>
        </div>
      </el-scrollbar>
    </ml-tip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTabberData, tabberHeight, searchParams, formetData } from '@/utils'
import ProcessSchedule from '@components/processSchedule.vue'

export default {
  name: 'SparePartsAction',
  components: {
    ProcessSchedule
  },
  setup() {
    const { commit, getters, dispatch } = useStore()
    const route = useRoute()
    const router = useRouter()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    // 获取路径参数
    const { recordId } = route.query
    // 设置导航标题文本
    const content = ref(recordId ? '修改备品' : '新增备品')
    /* 开始 表单 **********************************/
    // 详情数据
    const sparePartsFormRef = ref()
    // 表单配置
    const sparePartsForm = reactive({
      companyId: '',
      partName: '',
      partCode: '',
      productDate: '',
      model: '',
      unit: '',
      deadline: '',
      projectId: '',
      storageAddress: '',
      planStock: 0,
      realStock: 0,
      orderProcess: {
        processId: '',
        businessType: 'spare_part',
        nodeName: '',
        content: '',
        projectId: ''
      }
    })

    const processList = ref([])

    // 单位名下拉列表
    const unitOptions = ref([])
    // 所属项目下拉列表
    const projectData = ref([])
    // 流程列表下拉列表
    const processData = ref([])
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = { parent: 0 }
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const changeCompany = () => {
      sparePartsForm.projectId = ''
    }
    watch(
      () => sparePartsForm.companyId,
      newvalue => {
        getProjectData(newvalue)
        getProcessList(newvalue)
      }
    )

    const getProcessList = companyId => {
      dispatch('fetchOrderProcesss', { companyId }).then(data => {
        if (data.code === 200) {
          processData.value = data.data || []
        }
      })
    }
    // 取消表单
    const clickCancelSparePartsr = () => {
      router.push({ path: '/spareOutRecord' })
    }

    const getspareOutDetails = () => {
      dispatch('fetchGetSpareOutRecord', recordId).then(data => {
        if (data && data.code === 200) {
          let sparePartVo = data.data.sparePartVo || {}
          let orderProcess = data.data.orderProcess || {}
          processList.value = data.data.processList || []
          processList.value = processList.value.map(item => {
            return {
              ...item,
              userName: item.processUserName,
              operateTime: item.createTime,
              content: item.content ? '原因：' + item.content : '',
              title: item.nodeName
            }
          })
          Object.keys(sparePartsForm).map(item => {
            if (item === 'orderProcess') {
              sparePartsForm.orderProcess = {
                processId: orderProcess.processId,
                businessType: 'spare_part',
                nodeName: '',
                content: data.data.putReason,
                projectId: orderProcess.projectId
              }
            } else {
              sparePartsForm[item] = sparePartVo[item]
            }
          })
        }
      })
    }

    /* 结束 用户表单 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      if (data.length > 0) {
        sparePartsForm.companyId = data[0].companyId
      }
      if (content.value === '修改备品') {
        await getspareOutDetails()
      }
    })
    return {
      content,
      sparePartsForm,
      unitOptions,
      projectData,
      clickCancelSparePartsr,
      tabberHeight,
      sparePartsFormRef,
      changeCompany,
      processData,
      getProcessList,
      recordId,
      processList
    }
  }
}
</script>
  <style lang="scss">
.spareParts-action {
  position: relative;
  .ml-tree {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 10px 15px;
    .el-tree {
      > .el-tree-node {
        > .el-tree-node__children {
          > .el-tree-node {
            > .el-tree-node__children {
              white-space: break-spaces;
              > .el-tree-node {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .picTab {
    .el-tabs__nav-wrap::after {
      background-color: #fff !important;
    }
    .el-tabs__nav-scroll {
      padding: 0 40px;
    }
  }
  .man-class {
    display: inline-block;
    min-width: 100px;
  }
}
.ltitude-and-longitude {
  .el-dialog__body {
    padding: 0 !important;
  }
  .el-dialog__header {
    display: none;
  }
}
</style>
    